import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { TarotCard } from "../data/tarot";

type Props = {
  card: TarotCard;
  reverse?: boolean;
  disableFlip?: boolean;
  useOverlay?: boolean;
};

function Card({ card, reverse, disableFlip, useOverlay }: Props) {
  const [isFlipped, setIsFlipped] = useState(disableFlip ? true : false);

  const handleClick = () => {
    if (!disableFlip) {
      setIsFlipped(!isFlipped);
    }
  };

  useEffect(() => {
    if (!disableFlip) {
      setIsFlipped(false);
    }
  }, [disableFlip, card]);

  return (
    <div
      style={{ margin: "16px", cursor: disableFlip ? "default" : "pointer" }}
    >
      <ReactCardFlip isFlipped={isFlipped}>
        <div className="card flip-card" onClick={handleClick}>
          <div
            className={reverse ? "reverse" : undefined}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              lineHeight: "100%",
              color: "rgb(143, 11, 11)",
              fontSize: "128px",
            }}
          >
            <strong>⸸</strong>
          </div>
        </div>
        <div className="card flip-card" onClick={handleClick}>
          {card.numeral && (
            <div
              className={`card-name--${card.name}${reverse ? " reverse" : ""}`}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              {useOverlay && (
                <>
                  <div className="card--front-top">
                    <div className="card--numeral">{card.numeral}</div>
                  </div>
                  <div className="card--front-bottom">
                    <div className="card--name">{card.name}</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </ReactCardFlip>
    </div>
  );
}

export default Card;
