import { useState } from "react";
import { Player, GameState, View } from "../../../data/roulette";

type Props = {
  setGameState: React.Dispatch<React.SetStateAction<GameState>>;
  setPlayerOne: React.Dispatch<React.SetStateAction<Player>>;
  setPlayerTwo: React.Dispatch<React.SetStateAction<Player>>;
};

function ChooseNamesView({ setGameState, setPlayerOne, setPlayerTwo }: Props) {
  const [playerOneInput, setPlayerOneInput] = useState("Player 1");
  const [playerTwoInput, setPlayerTwoInput] = useState("Player 2");

  const handleGameStart = () => {
    setPlayerOne((prevState) => {
      return { ...prevState, name: playerOneInput };
    });
    setPlayerTwo((prevState) => {
      return { ...prevState, name: playerTwoInput };
    });
    setGameState((prevState) => {
      return {
        ...prevState,
        view: View.CoinFlip,
        goesFirst: Math.floor(Math.random() * 2) + 1,
      };
    });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>Choose Player Names</strong>
        <div style={{ padding: "16px 0" }}>
          <input
            value={playerOneInput}
            onChange={({ target }) => setPlayerOneInput(target.value)}
            className="input--red"
          />
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <input
            value={playerTwoInput}
            onChange={({ target }) => setPlayerTwoInput(target.value)}
            className="input--red"
          />
        </div>
        <button
          onClick={handleGameStart}
          className="tarot-button tarot-button--small"
        >
          Game Start
        </button>
      </div>
    </div>
  );
}

export default ChooseNamesView;
