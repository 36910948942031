type Path = {
  // The text and positioning of the answer input
  answerText: (string | "$TEXT_INPUT_1" | "$TEXT_INPUT_2" | "$TEXT_INPUT_3")[];
  // Which challenge subpage displays the input
  inputPart: "" | "+1" | "+2" | "+3" | "+4" | "+5" | undefined;
};

export const paths: Path[] = [
  { answerText: ["The meaning is ", "$TEXT_INPUT_1"], inputPart: "+2" },
  {
    answerText: ["It's called ", "$TEXT_INPUT_1", " of ", "$TEXT_INPUT_2"],
    inputPart: "",
  },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined },
  { answerText: [], inputPart: undefined }, // Challenge is over
];
