import { useEffect, useState, useCallback } from "react";

type Return = {
  loadingMD: boolean;
  markdownContent: string;
  func: () => Promise<void>;
  exists: boolean;
};

function useFetchMD(fullPath: string, immediate?: true): Return {
  const [loadingMD, setLoadingMD] = useState(true);
  const [markdownContent, setMarkdownContent] = useState("");
  const [exists, setExists] = useState(false);

  const func = useCallback(async () => {
    return fetch(`${fullPath}.md`)
      .then((response) => response.text())
      .then((text) => {
        setLoadingMD(false);
        if (text.startsWith("<!")) {
          setExists(false);
          return setMarkdownContent(
            `# Nothing. . .\n_You must be lost. It's okay, so am I._`
          );
        }
        setExists(true);
        return setMarkdownContent(text);
      });
  }, [fullPath]);

  useEffect(() => {
    if (immediate) {
      func();
    }
  }, [func, fullPath, immediate]);

  return { loadingMD, markdownContent, func, exists };
}

export default useFetchMD;
