import { Player, PlayerId, GameState } from "../../../data/roulette";

type Props = {
  gameState: GameState;
  playerOne: Player;
  playerTwo: Player;
};

function CoinFlipView({ gameState, playerOne, playerTwo }: Props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>
          {gameState.goesFirst === PlayerId.Player1
            ? playerOne.name
            : playerTwo.name}{" "}
          will go first.{" "}
          {gameState.goesFirst === PlayerId.Player1
            ? playerTwo.name
            : playerOne.name}{" "}
          will get an additional item.
        </p>
        <button className="tarot-button tarot-button--small">Ok</button>
      </div>
    </div>
  );
}

export default CoinFlipView;
