import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Layout from "./Layout";
import KnowThyDaemons from "./KnowThyDaemons";
import useFetchMD from "../hooks/useFetchMD";

type Props = {
  index?: boolean;
};

function PageView({ index }: Props) {
  const star = useParams()["*"];
  const { loadingMD, markdownContent } = useFetchMD(
    `/md/${index ? "index" : star ?? ""}`,
    true
  );

  return (
    <Layout index={index}>
      <ReactMarkdown children={markdownContent} />
      {star === "oracle-guide" && !loadingMD && <KnowThyDaemons />}
    </Layout>
  );
}

export default PageView;
