export type TarotCard = {
  numeral: string;
  name: string;
  description?: string;
  reverseDescription?: string;
  affirmation?: string;
  affinities?: string[];
  relations?: string[];
};

export type TarotDeck = {
  name: string;
  symbol: string;
  cards: TarotCard[];
};

/*/// Decks ///*/

export const daemonTarot: TarotDeck = {
  name: "Daemon Oracle",
  symbol: "⛧",
  cards: [
    {
      numeral: "0",
      name: "Zagan",
      description:
        "Zagan is a daemon of transformation and wit, presence indicating subtle shiftings which shall eventually bring forth great change.",
      reverseDescription:
        "Beware of unintended changes coming from a lack of clear intention in your actions. Know your aims and move forward with purpose.",
      affirmation: "I fearlessly embrace the new.",
      affinities: ["Metal"],
      relations: ["Guard of Ipos"],
    },
    {
      numeral: "I",
      name: "Balam",
      description:
        "Balam is a solitary creature of the night and indicates withdrawal either from people or potential conflict. Within the safety of shadows, a plot brews.",
      reverseDescription:
        "Hiding away has done no good. You will be seen for who you are. Be ready to show yourself on your own terms if you wish to take control.",
      affirmation: "My own unique power burns within me.",
      affinities: ["Air", "Fire"],
      relations: ["Rival of Aym"],
    },
    {
      numeral: "II",
      name: "Gremory",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "III",
      name: "Buer",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "IV",
      name: "Orobas",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "V",
      name: "Andras",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "VI",
      name: "Furfur",
      description:
        "Furfur takes on the form of lovers. Whether we love ourselves or others, it is the same; for all are connected, and all are as one. This is a reminder of unity in all things.",
      reverseDescription:
        "There is conflict between dualistic forces. Beware the lies you tell yourself. As you honestly overcome hardships, natural powers shall come under your control.",
      affirmation: "I deserve to love and be loved.",
      affinities: ["Devotion", "Thorns"],
      relations: ["Elder Sibling of Andras"],
    },
    {
      numeral: "VII",
      name: "Marchosias",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "VIII",
      name: "Auns",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "IX",
      name: "Stolas",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "X",
      name: "Bune",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XI",
      name: "Astaroth",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XII",
      name: "Kimaris",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XIII",
      name: "Ronove",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XIV",
      name: "Asmodeus",
      description:
        "Now is the time to act in moderation. All things have their place, whether Dark or Light, Feminine or Masculine, Human or Beast.",
      reverseDescription:
        "Something hinders or oppresses you, but you are a free spirit. Know who you are and tear off the chains. Liberation shall follow your efforts.",
      affirmation: "I retain a balanced perspective in all things.",
      affinities: ["Fire"],
      relations: ["Goddharc of the Wild"],
    },
    {
      numeral: "XV",
      name: "Baal",
      description: "",
      reverseDescription: "",
      affirmation: "I place truth above comfort",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XVI",
      name: "Vine",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XVII",
      name: "Orias",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XVIII",
      name: "Allocer",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XIX",
      name: "Aym",
      description:
        "Whether Aym's fire brings life-giving warmth or dreadful destruction is a matter of intensity. Know your strength and use it wisely.",
      reverseDescription:
        "You've burned something or someone close to you. Was this an intended act that brought liberation, or was it a mistake? If the latter, forgive yourself.",
      affirmation: "I am surrounded by the light lit by my own flame.",
      affinities: ["Fire", "Sun"],
      relations: ["Rival of Balam"],
    },
    {
      numeral: "XX",
      name: "Vassago",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
    {
      numeral: "XXI",
      name: "Ipos",
      description: "",
      reverseDescription: "",
      affirmation: "",
      affinities: [],
      relations: [],
    },
  ],
};
