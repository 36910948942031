import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageView from "./components/PageView";
import TarotView from "./components/TarotView";
import ChallengeView from "./components/ChallengeView";
import RouletteView from "./components/boom-roulette/RouletteView";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageView index />} />
        <Route path="/oracle" element={<TarotView />} />
        <Route path="/roulette" element={<RouletteView />} />
        <Route path="/craobh/:n/:path" element={<ChallengeView />} />
        <Route path="/*" element={<PageView />} />
      </Routes>
    </Router>
  );
}

export default App;
