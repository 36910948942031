import { useState, useMemo } from "react";
import {
  Player,
  gameConfiguration,
  GameState,
  View,
} from "../../data/roulette";
import ChooseNamesView from "./subviews/ChooseNamesView";
import CoinFlipView from "./subviews/CoinFlipView";

function RouletteView() {
  const [gameState, setGameState] = useState<GameState>({
    round: 1,
    turn: 1,
    goesFirst: undefined,
    selectedItems: [],
    view: View.ChooseNames,
    shells: [],
  });
  const [playerOne, setPlayerOne] = useState<Player>({
    name: "",
    health: gameConfiguration.maxHealth,
    items: [],
  });
  const [playerTwo, setPlayerTwo] = useState<Player>({
    name: "",
    health: gameConfiguration.maxHealth,
    items: [],
  });

  const views: { [key in View]: JSX.Element } = useMemo(
    () => ({
      [View.ChooseNames]: (
        <ChooseNamesView
          setGameState={setGameState}
          setPlayerOne={setPlayerOne}
          setPlayerTwo={setPlayerTwo}
        />
      ),
      [View.CoinFlip]: (
        <CoinFlipView
          gameState={gameState}
          playerOne={playerOne}
          playerTwo={playerTwo}
        />
      ),
      [View.ShowShells]: <>test</>,
      [View.PlayerTurnStart]: <>test</>,
      [View.PlayerChooseTarget]: <>test</>,
      [View.TargetResult]: <>test</>,
      [View.RoundOver]: <>test</>,
      [View.GameResult]: <>test</>,
    }),
    [gameState, playerOne, playerTwo]
  );

  return (
    <div>
      <h1>Boom Roulette</h1>
      <div>{views[gameState.view]}</div>
    </div>
  );
}

export default RouletteView;
