type Props = {
  children: React.ReactNode;
  index?: boolean;
  starURL?: string;
};

function Layout({ index, children, starURL }: Props) {
  return (
    <div>
      {!index && (
        <a href="/" className="a-special">
          <strong>⸸</strong>
        </a>
      )}
      {children}
      <div style={{ textAlign: "center", marginBottom: "48px" }}>
        <hr style={{ border: "1px solid rgb(37, 38, 40)", margin: "16px 0" }} />
        `ﾟ・✧･ﾟ *･ﾟ*✧･ﾟ *･ﾟ*
        {starURL ? (
          <a className="a-special" href={starURL}>
            ✧
          </a>
        ) : (
          "✧"
        )}
        ･ﾟ *･・ﾟ`
      </div>
    </div>
  );
}

export default Layout;
