import { useState, useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import Layout from "./Layout";
import { TarotCard, daemonTarot } from "../data/tarot";
import Card from "./Card";

type Props = {
  index?: boolean;
};

function TarotView({ index }: Props) {
  const [cards, setCards] = useState<({ reverse: boolean } & TarotCard)[]>([]);
  const [number, setNumber] = useState(1);

  const handleEvoke = useCallback(() => {
    const indices = Array.from(
      { length: daemonTarot.cards.length },
      (_, i) => i
    );
    // Shuffle
    for (let i = indices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [indices[i], indices[j]] = [indices[j], indices[i]];
    }
    const selectedIndices = indices.slice(0, number);
    const selectedCards = selectedIndices.map((index) => ({
      ...daemonTarot.cards[index],
      reverse: Math.random() <= 0.2, // 20% chance of reversal
    }));
    if (cards.length === 0) {
      setCards(selectedCards);
    } else {
      setCards((prevState) =>
        selectedIndices.map((_, i) => {
          return { ...prevState[i] };
        })
      );
      setTimeout(() => {
        setCards(selectedCards);
      }, 250);
    }
  }, [cards, number, setCards]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNumber(Number(event.target.value));
    },
    [setNumber]
  );

  // This has a 13.6% chance
  const thirdEyeOpen = useMemo(
    () => cards.length === 3 && cards[1].numeral === "XIV",
    [cards]
  );

  return (
    <Layout index={index} starURL={thirdEyeOpen ? "/treas-suil" : undefined}>
      <ReactMarkdown children={`# ${daemonTarot.name}`} />
      {cards.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {cards.map((c) => (
            <Card card={c} reverse={c.reverse} />
          ))}
        </div>
      )}
      <div style={{ textAlign: "center", flexDirection: "column" }}>
        <div style={{ color: "red", marginTop: "16px" }}>
          Evoke
          <select
            className="input--red"
            name="card-number"
            value={number}
            onChange={handleChange}
          >
            {["I", "II", "III", "IV", "V"].map((n, i) => (
              <option value={i + 1}>{n}</option>
            ))}
          </select>
        </div>
        <button className="tarot-button" onClick={handleEvoke}>
          {daemonTarot.symbol}
        </button>
      </div>
    </Layout>
  );
}

export default TarotView;
