import ReactMarkdown from "react-markdown";
import { daemonTarot } from "../data/tarot";
import Card from "./Card";

function KnowThyDaemons() {
  return (
    <>
      <div>
        {daemonTarot.cards.map((c) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2>
              {c.numeral} - {c.name}
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Card card={c} disableFlip />
              <Card card={c} disableFlip reverse />
            </div>
            <div>
              <div style={{ textAlign: "center" }}>
                <hr />
                <em>{c.affirmation}</em>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ padding: "12px" }}>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "24px",
                      }}
                    >
                      <strong>⸸</strong>
                    </div>
                    {c.description}
                  </div>
                  <div style={{ padding: "12px" }}>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "24px",
                        transform: "scale(1, -1)",
                      }}
                    >
                      <strong>⸸</strong>
                    </div>
                    {c.reverseDescription}
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ display: "flex" }}>
                <div>
                  <h4>Affinity</h4>
                  <ul>
                    {c.affinities?.map((a) => (
                      <li>{a}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4>Relations</h4>
                  <ul>
                    {c.relations?.map((r) => (
                      <li>{r}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactMarkdown children={`###\n[Close](/bookshelf) the book.`} />
    </>
  );
}

export default KnowThyDaemons;
