import { useState, useCallback, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Layout from "./Layout";
import useFetchMD from "../hooks/useFetchMD";
import { paths } from "../data/paths";

function ChallengeView() {
  const { n, path } = useParams();
  const { markdownContent: currentMd, exists } = useFetchMD(
    `/md/craobh${n && path ? `/${n}/${path}` : ""}`,
    true
  );
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [givenAnswer, setGivenAnswer] = useState("");

  const challengeNumber = (
    n && !isNaN(parseFloat(n ?? "")) ? parseInt(n) : 0
  ) as number;

  const { func, exists: correct } = useFetchMD(
    `/md/craobh/${challengeNumber + 1}/${givenAnswer}`
  );

  const handleSubmit = async () => {
    setGivenAnswer(`${input1}${input2}${input3}`);
  };

  useEffect(() => {
    if (givenAnswer) {
      func();
    }
  }, [func, givenAnswer]);

  useEffect(() => {
    setGivenAnswer("");
  }, [challengeNumber]);

  const inputMapping = useMemo(
    () => ({
      $TEXT_INPUT_1: [input1, setInput1],
      $TEXT_INPUT_2: [input2, setInput2],
      $TEXT_INPUT_3: [input3, setInput3],
    }),
    [input1, input2, input3, setInput1, setInput2, setInput3]
  );

  const answerDisplayElement = useCallback(() => {
    return paths[challengeNumber].answerText.map((str) => {
      if (str.startsWith("$TEXT_INPUT_")) {
        return (
          <input
            key={str}
            value={inputMapping[str as "$TEXT_INPUT_1"][0] as string}
            onChange={(e) =>
              (
                inputMapping[str as "$TEXT_INPUT_1"][1] as React.Dispatch<
                  React.SetStateAction<string>
                >
              )(e.target.value)
            }
            className="input--red"
          />
        );
      }
      return <span key={str}>{str}</span>;
    });
  }, [challengeNumber, inputMapping]);

  return (
    <Layout>
      <ReactMarkdown children={`# Tree of Life\n${currentMd}`} />
      {exists &&
        paths[challengeNumber].inputPart !== undefined &&
        window.location.href.endsWith(
          paths[challengeNumber].inputPart as string
        ) && (
          <p style={{ display: "flex", alignItems: "center" }}>
            <em>{answerDisplayElement()}</em>
            <button
              style={{ position: "relative", bottom: "6px" }}
              className="tarot-button tarot-button--small"
              onClick={handleSubmit}
            >
              ⸸
            </button>
          </p>
        )}
      {givenAnswer && (
        <p>
          <strong>Answer: </strong>
          {correct ? (
            <a
              style={{ color: "red" }}
              href={`/craobh/${challengeNumber + 1}/${givenAnswer}`}
            >
              {givenAnswer}
            </a>
          ) : (
            <span style={{ color: "gray" }}>
              <s>{givenAnswer}</s>
            </span>
          )}
        </p>
      )}
    </Layout>
  );
}

export default ChallengeView;
