export enum PlayerId {
  Player1 = 1,
  Player2 = 2,
}

enum ItemType {
  Cigarette = 1,
  Saw = 2,
  Beer = 3,
  MagnifyingGlass = 4,
  Handcuffs = 5,
}

export enum View {
  ChooseNames = 1,
  CoinFlip = 2,
  ShowShells = 3,
  PlayerTurnStart = 4,
  PlayerChooseTarget = 5,
  TargetResult = 6,
  RoundOver = 7,
  GameResult = 8,
}

export enum Shell {
  Blank = 1,
  Live = 2,
}

type Item = {
  id: ItemType;
  description: string;
};

export type GameState = {
  round: number;
  turn: number;
  goesFirst: undefined | PlayerId.Player1 | PlayerId.Player2;
  selectedItems: Item[];
  view: View;
  shells: Shell[];
};

export type Player = {
  name: string;
  health: number;
  items: [];
};

export const items: Item[] = [
  {
    id: ItemType.Cigarette,
    description: "",
  },
  {
    id: ItemType.Saw,
    description: "",
  },
  {
    id: ItemType.Beer,
    description: "",
  },
  {
    id: ItemType.MagnifyingGlass,
    description: "",
  },
  {
    id: ItemType.Handcuffs,
    description: "",
  },
];

export const gameConfiguration = {
  maxRounds: 3,
  maxHealth: 6,
  minShells: 2,
  maxShells: 8,
};
